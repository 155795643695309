import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CoreModule } from '@core';
import { SharedModule } from '@shared';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import {
  AptButtonModule,
  AptFormModule,
  AptLayoutModule,
  AptTypographyModule,
  AptTemplatesModule,
  AptCardsModule,
  AptAuthSyncModule,
} from '@archinsurance-da/arch-portal-tools';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HomeService } from './home.service';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    AptButtonModule,
    AptFormModule,
    AptLayoutModule,
    AptTypographyModule,
    AptTemplatesModule,
    HomeRoutingModule,
    AptCardsModule,
    AptAuthSyncModule,
  ],
  declarations: [HomeComponent],
  providers: [HomeService],
})
export class HomeModule {}
