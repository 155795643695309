import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { Logger } from '@core/logger.service';
import { environment } from '@env/environment';
import { constants } from './../@shared/constants';
import { ApiHttpService } from '@core/http/api-http.service';
export interface UserProfile {
  given_name: string;
  family_name: string;
  nickname: string;
  name: string;
  email: string;
  email_verified: boolean;
  groups: string[];
  roles: string[];
}

declare let pendo: any;

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private log = new Logger('AuthenticationService');

  private authenticatedSubject = new BehaviorSubject<boolean>(false);
  private loadedSubject = new ReplaySubject<boolean>();
  private _loaded = this.loadedSubject.asObservable();
  private _authenticated = this.authenticatedSubject.asObservable();

  private _userProfile: UserProfile;
  private pendoInitialized = false;

  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private ngZone: NgZone,
    private apiHttpService: ApiHttpService
  ) {
    this.oauthService.events.subscribe((event) => {
      this.authenticatedSubject.next(this.oauthService.hasValidAccessToken());

      if (event instanceof OAuthErrorEvent) {
        this.log.error(event);
      } else {
        this.log.info(event);
      }
    });

    this.oauthService.events.pipe(filter((event) => ['token_received'].includes(event.type))).subscribe((e) => {
      //In case of selfheal success and failure loading userprofile
      //in case of success : after self heal correct roles will loadup
      //In case of failure : Normal flow will work
      //currently if self heal only updating database depend on token in future when reverse case happen this will be useful.
      this.selfHealUserRoles().subscribe(
        (data: any) => {
          this.loadUserProfile();
        },
        (dataError: any) => {
          this.loadUserProfile();
          console.log('warn', dataError);
        }
      );
    });

    this.oauthService.events.pipe(filter((event) => ['token_expires'].includes(event.type))).subscribe((e) => {
      this.logout();
    });

    this.oauthService.events
      .pipe(filter((event) => ['session_terminated', 'session_error'].includes(event.type)))
      .subscribe((event) => this.login());

    window.addEventListener('storage', (event) => {
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      this.log.warn('Noticed changes to access_token (most likely from another tab), updating auth state');

      const authenticated = this.oauthService.hasValidAccessToken();
      this.authenticatedSubject.next(authenticated);

      if (!authenticated) {
        this.login();
      }
    });

    this.oauthService.setupAutomaticSilentRefresh();
  }

  public login(targetUrl?: string) {
    this.oauthService.initLoginFlow(encodeURIComponent(targetUrl || this.router.url));
  }

  hasValidAccessToken() {
    return this.oauthService.hasValidAccessToken();
  }

  public runInitialLoginSequence(entrypoint: boolean = false): Promise<void> {
    return this.oauthService
      .loadDiscoveryDocumentAndTryLogin()
      .then(() => {
        if (this.oauthService.hasValidAccessToken()) {
          return Promise.resolve();
        } else {
          entrypoint ? this.loginRedirectUrl() : this.login();
          //this.login();
        }

        return Promise.reject();
      })
      .then(() => {
        this.loadedSubject.next(true);
        this.loadUserProfile();

        // const state = this.oauthService.state;
        // if (state && state !== 'undefined' && state !== 'null') {
        //   this.log.debug('There was state, so we sending you to: ' + this.oauthService.state);
        //   this.router.navigateByUrl(state);
        // }
        const state = decodeURIComponent(decodeURIComponent(this.oauthService.state));
        if (!this.checkRoles()) {
          this.router.navigate(['access-denied']);
        } else if (state && state !== 'undefined' && state !== 'null') {
          if (state == '/login') {
            this.router.navigateByUrl('');
          } else {
            this.log.debug(`There was state, so we're sending you to: ${state}`);
            this.router.navigateByUrl(state);
          }
        } else if (this.router.url === '/access-denied') {
          this.router.routeReuseStrategy.shouldReuseRoute = function () {
            //this fixes route not reloading after change route below...
            return false;
          };
          setTimeout(() => {
            this.router.navigated = false;
            this.router.navigate(['']); //if has role access and is on the access denied page, forward to landing page
          }, 200);
        }
      })
      .catch((err) => {
        console.log(err);
        this.loadedSubject.next(true);
      });
  }

  public checkRoles(): boolean {
    //console.log(this.isUserInternal());
    if (this.isENVValid() && (this.isUserInternal() || environment.externalUserFlag)) {
      return true;
    } else {
      return false;
    }
  }

  public isENVValid(): boolean {
    if (environment.SKIP_ENV_ROLE_CHECK) {
      return environment.SKIP_ENV_ROLE_CHECK;
    } else {
      return this._userProfile.roles.includes(
        `${constants.AIP_USER_ROLE.toUpperCase()}-${environment.environmentName.toUpperCase()}`
      );
    }
  }

  public isUserInternal(): boolean {
    let emailId = this._userProfile?.email;
    if (emailId) {
      var domain = emailId.substring(emailId.lastIndexOf('@') + 1);
      return constants.INTERNAL_EMAIL_DOMAIN.some((i: any) => i.includes(domain));
    }
    return false;
  }

  public logout() {
    //window.localStorage.clear();
    this.oauthService.logOut();
  }

  get authenticated(): Observable<boolean> {
    return this._authenticated;
  }

  get loaded(): Observable<boolean> {
    return this._loaded;
  }

  get userProfile(): UserProfile {
    return this._userProfile;
  }

  private loadUserProfile() {
    this._userProfile = this.oauthService.getIdentityClaims() as UserProfile;
    this._userProfile.groups = this.oauthService.getIdentityClaims()['http://www.archinsurance.com/claims/groups'];
    this._userProfile.roles = this.userProfile['http://www.archinsurance.com/claims/roles'];
    //console.log('load user profile from auth', this._userProfile);
    // Only initialize pendo once
    if (!this.pendoInitialized) {
      this.pendoInitialized = true;
      this.initializePendo(this._userProfile);
    }
  }

  /**
   * Arch landing page login redirect
   */
  public loginRedirectUrl() {
    this.router.navigateByUrl('/login');
  }

  private initializePendo(userInfo: UserProfile) {
    this.ngZone.runOutsideAngular(() => {
      const emailId = !!userInfo.email ? userInfo.email : 'EMAIL-NOT-FOUND';
      const fullName = !!userInfo.name ? userInfo.name : 'NAME-NOT-FOUND';
      const role = !!userInfo['http://www.archinsurance.com/claims/roles']
        ? userInfo['http://www.archinsurance.com/claims/roles'].toString()
        : null;

      pendo.initialize({
        visitor: {
          id: emailId, // Required if user is logged in
          email: emailId, // Recommended if using Pendo Feedback, or NPS Email
          full_name: fullName, // Recommended if using Pendo Feedback
          role,
        },

        account: {
          id: 'ACCOUNT-UNIQUE-ID', // Highly recommended
        },
      });
    });
  }

  /**
   * Implicit silent refresh
   * @returns boolean
   */
  isSilentRefresh(): Observable<boolean> {
    return new Observable((observer) => {
      this.oauthService
        .silentRefresh()
        .then((info) => {
          //this.loadUserProfile();
          observer.next(true);
          observer.complete();
          console.log('refresh ok');
        })
        .catch((err: any) => {
          //this.loadUserProfile();

          observer.error(err);
          observer.complete();
          console.log('refresh error', err);
        });
    });
  }
  selfHealUserRoles(): Observable<any> {
    return this.apiHttpService.post(environment.aipHostPortAdmin + constants.serviceEndPointSelfHealUser, {}).pipe(
      map((result) => {
        return result;
      })
    );
  }
}
