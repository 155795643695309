import { Routes, Route } from '@angular/router';

import { StatusShellComponent } from './shell.component';
// import { AuthenticationGuard } from '@app/authentication/authentication.guard';

/**
 * Provides helper methods to create routes.
 */
export class StatusShell {
  /**
   * Creates routes using the shell component and authentication.
   * @param routes The routes to add.
   * @return The new route using shell as the base.
   */
  static childRoutes(routes: Routes): Route {
    return {
      path: '',
      component: StatusShellComponent,
      children: routes,
      // canActivate: [AuthenticationGuard],
      // Reuse ShellComponent instance when navigating between child views
      data: { reuse: true },
    };
  }
}
