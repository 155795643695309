export const constants = {
  adminPortalEndpoint: '/admin',

  topmenuEndPoint: 'aip-authorization-ms/authorize/menuset/menu',
  // archConnectHost: '/', //for use with api proxy on local

  serviceEndPointsContentServer: 'audit/resourcePath?appName=',
  userClaims: 'aipsecurityapi/loginUnregisteredUserClaims',

  //Base API
  permissionEndPoint: 'getRolesAndPortals',
  serviceEndPointUserDetail: '/getUserdetails',
  sendEmailPath: '/sendRequestAccessEmail',

  //Admin API
  getRolesAgencies: '/aip-admin-api/getRolesAgenciesAndProducts',
  getPortals: '/aip-admin-api/getPortals',
  getSignature: '/aip-admin-api/getUserSignature',
  updateSignature: '/aip-admin-api/saveUserSignature',
  serviceEndPointDeleteUser: '/aip-admin-api/deleteUser',
  serviceEndPortals: '/aip-admin-api/getPortals',
  serviceEndPointaddUser: '/aip-admin-api/addUser',
  serviceEndPointGetRolesAgenciesAndProducts: '/aip-admin-api/getRolesAgenciesAndProducts',
  serviceEndPointSelfHealUser: '/aip-admin-api/selfHealUser',

  //Common constant variables
  AIP_USER_ROLE: 'AIGI-ROLE-DIGAIP-USER',
  AIP_ARCH_ROLE: 'AIGI-ROLE-DIGAIP-ARCH',
  AIP_AGENCY_ROLE: 'AIGI-ROLE-DIGAIP-AGENCY',
  AIP_ADMIN_ROLE: 'AIGI-ROLE-DIGAIP-ADMIN',
  CAS_FUNCTIONAL_ROLE_ARCH: 'AIGI-ROLE-CAS-ARCH',
  CAS_FUNCTIONAL_ROLE_AGENCY: 'AIGI-ROLE-CAS-AGENCY',
  CAS_ENV_ROLE: 'AIGI-ROLE-OBCAS-USER',
  DIGDA_FUNCTIONAL_ROLE_ARCH: 'AIGI-ROLE-DIGDA-ARCH',
  DIGDA_FUNCTIONAL_ROLE_AGENCY: 'AIGI-ROLE-DIGDA-AGENCY',
  DIGDA_ENV_ROLE: 'AIGI-ROLE-DIGDA-USER',
  FPL_FUNCTIONAL_ROLE_ARCH: 'AIGI-ROLE-OBFPL-ARCH',
  FPL_FUNCTIONAL_ROLE_AGENCY: 'AIGI-ROLE-OBFPL-AGENCY',
  FPL_ENV_ROLE: 'AIGI-ROLE-OBFPL-USER',
  CLAIMS_FUNCTIONAL_ROLE_USER: 'AIGI-ROLE-CLAIMS-USER',
  CLAIMS_FUNCTIONAL_ROLE_ADMIN: 'AIGI-ROLE-CLAIMS-ADMIN',
  CLAIMS_FUNCTIONAL_ROLE_ARCH: 'AIGI-ROLE-CLAIMS-ARCH',
  CLAIMS_FUNCTIONAL_ROLE_AGENCY: 'AIGI-ROLE-CLAIMS-AGENCY',
  CLAIMS_FUNCTIONAL_ROLE_UNDERWRITER: 'AIGI-ROLE-CLAIMS-UNDERWRITER',
  CLAIMS_FUNCTIONAL_ROLE_TPA: 'AIGI-ROLE-CLAIMS-TPA',
  CLAIMS_FUNCTIONAL_ROLE_SYSTEM: 'AIGI-ROLE-CLAIMS-SYSTEM',
  CLAIMS_FUNCTIONAL_ROLE_POLICY_HOLDER: 'AIGI-ROLE-CLAIMS-POLICY-HOLDER',
  CLAIMS_FUNCTIONAL_ROLE_MANAGER: 'AIGI-ROLE-CLAIMS-MANAGER',
  CLAIMS_FUNCTIONAL_ROLE_INVOICE_USER: 'AIGI-ROLE-CLAIMS-INVOICE-USER',
  CLAIMS_FUNCTIONAL_ROLE_INVOICE_MANAGER: 'AIGI-ROLE-CLAIMS-INVOICE-MANAGER',
  CLAIMS_FUNCTIONAL_ROLE_INVOICE_ADMIN: 'AIGI-ROLE-CLAIMS-INVOICE-ADMIN',
  CLAIMS_FUNCTIONAL_ROLE_INTERNAL: 'AIGI-ROLE-CLAIMS-INTERNAL',
  CLAIMS_FUNCTIONAL_ROLE_BROKER: 'AIGI-ROLE-CLAIMS-BROKER',

  CLAIMS_ENV_ROLE: 'AIGI-ROLE-CLAIMS-USER',
  SURETY_FUNCTIONAL_ROLE_UW: 'AIGI-ROLE-SURETY-UNDERWRITER',
  SURETY_FUNCTIONAL_ROLE_AGENT: 'AIGI-ROLE-SURETY-AGENT',
  SURETY_ENV_ROLE: 'AIGI-ROLE-SURETY-USER',
  POP_FUNCTIONAL_ROLE: 'AIGI-ROLE-POP-ARCH-PROGRAM-MANAGER',
  POP_ENV_ROLE: 'AIGI-ROLE-POP-USER',
  SUBMISSION_ENV_ROLE: 'AIGI-ROLE-SUBMISSION-USER',
  HEALTHCARE_ENV_ROLE: 'AIGI-ROLE-FPHC-USER',
  // UNDERWRITER_ENV_ROLE: 'AINA-PL-UNDERWRITER-USER',
  ARCH: 'ARCH',
  AGENCY: 'AGENCY',
  AGENCY_ROLE: 'AIGI-ROLE-AGENCY-UNDERWRITER',
  ARCH_ROLE: 'AIGI-ROLE-ARCH-UNDERWRITER',
  internal: 'ARCH',
  external: 'AGENCY',
  internalUser: 'Internal',
  externalUser: 'External',
  INTERNAL_EMAIL_DOMAIN: [
    'archinsurance.com',
    'archgroup.com',
    'archcapservices.com',
    'archgso.com',
    'corp.archcapservices.com',
  ],
  SKIP_PORTALS_INTERNAL_USER: ['AIGI-BasePortal'],
  SKIP_PORTALS_EXTERNAL_USER: ['AIGI-BasePortal', 'AIGI-ProgramsOperationsPortal'],
  AUTO_PORTALS_INTERNAL_USER: [{ 'AIGI-BasePortal': 'AIGI-ROLE-DIGAIP-ARCH-UNDERWRITER' }],
  AUTO_PORTALS_EXTERNAL_USER: [{ 'AIGI-BasePortal': 'AIGI-ROLE-DIGAIP-AGENCY-UNDERWRITER' }],
  maintenanceStartDate: '07/15/2022 22:00:00 GMT-0400 (Eastern Daylight Time)',
  maintenanceEndDate: '07/16/2022 02:00:00 GMT-0400 (Eastern Daylight Time)',
  warnMinutesBefore: 5760,

  INTERNAL_ROLES: [
    'AIGI-ROLE-PONG-READONLY',
    'AIGI-ROLE-PONG-WRITE',
    'AIGI-ROLE-LOAD-READONLY',
    'AIGI-ROLE-LOAD-WRITE',
    'AIGI-ROLE-CLAIMS-ADMIN',
    'AIGI-ROLE-CLAIMS-INTERNAL',
    'AIGI-ROLE-CLAIMS-INVOICE-ADMIN',
    'AIGI-ROLE-CLAIMS-INVOICE-MANAGER',
    'AIGI-ROLE-CLAIMS-INVOICE-USER',
    'AIGI-ROLE-CLAIMS-MANAGER',
    'AIGI-ROLE-CLAIMS-POLICY-HOLDER',
    'AIGI-ROLE-CLAIMS-SYSTEM',
    'AIGI-ROLE-CLAIMS-UNDERWRITER',
    'AIGI-ROLE-CLAIMS-USER',
    'AIGI-ROLE-SURETY-UNDERWRITER',
    'AIGI-ROLE-CLAIMS-VP',
    'AIGI-ROLE-CLAIMS-EXAMINER',
    'AIGI-ROLE-CLAIMS-TPA-EXAMINER',
    'AIGI-ROLE-CLAIMS-TPA-SUPERVISOR',
  ],
  EXTERNAL_ROLES: ['AIGI-ROLE-CLAIMS-BROKER', 'AIGI-ROLE-CLAIMS-TPA', 'AIGI-ROLE-SURETY-AGENT'],
  PONG_ENV_ROLE: 'AIGI-ROLE-PONG-USER',
  LOA_ENV_ROLE: 'AIGI-ROLE-LOAD-USER',
  SATELLITE_ENV_ROLE: 'AIGI-ROLE-SATELLITE-USER',
};
