import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiEndpointsService } from '@core/http/api-endpoints.service';
import { ApiHttpService } from '@core/http/api-http.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { constants } from './../../@shared/constants';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  constructor(
    private apiEndPoint: ApiEndpointsService,

    private apiHttpService: ApiHttpService,
    private authenticationService: AuthenticationService
  ) {}

  getUserEmail() {
    const helper = new JwtHelperService();
    let idToken = localStorage.getItem('id_token').toString(); //token from local storage
    let decodedJWT = helper.decodeToken(idToken);
    if (decodedJWT) {
      return decodedJWT['email'];
    } else {
      return null;
    }
  }

  getUserfullName() {
    const helper = new JwtHelperService();
    let idToken = localStorage.getItem('id_token').toString(); //token from local storage
    let decodedJWT = helper.decodeToken(idToken);
    if (decodedJWT) {
      return decodedJWT['given_name'] + ' ' + decodedJWT['family_name'];
    } else {
      return null;
    }
  }

  isUserAdmin() {
    const helper = new JwtHelperService();
    let idToken = localStorage.getItem('id_token').toString(); //token from local storage
    let decodedJWT = helper.decodeToken(idToken);
    let envRoles = decodedJWT['http://www.archinsurance.com/claims/roles'];
    if (envRoles && envRoles.find((role: string) => role.includes(constants.AIP_ADMIN_ROLE))) {
      return 'Y';
    } else {
      return 'N';
    }
  }

  getSettingMenu(): Array<Object> {
    let idToken = localStorage.getItem('id_token').toString(); //token from local storage
    const helper = new JwtHelperService();
    let decodedJWT = helper.decodeToken(idToken); //decoding it
    //decodedJWT['http://www.archinsurance.com/claims/roles'] = environment.mockRoleData;

    if (decodedJWT) {
      if (decodedJWT.hasOwnProperty('http://www.archinsurance.com/claims/roles')) {
        let envRoles = decodedJWT['http://www.archinsurance.com/claims/roles']; // assign roles key to variable
        let adminMenu: any = [];
        let nonAdminMenu: any = [];
        let agencyMenu: any = [];
        if (envRoles && envRoles.find((role: string) => role.includes(constants.AIP_ADMIN_ROLE))) {
          adminMenu = [
            {
              text: 'Manage User',
              action: () => {
                this.navigateToAdmin();
              },
            },
            {
              text: 'My Profile',
              action: () => {
                this.navigateRoute('profile');
              },
            },
          ];
        } else {
          nonAdminMenu = [
            {
              text: 'View User',
              action: () => {
                this.navigateToAdmin();
              },
            },
            {
              text: 'My Profile',
              action: () => {
                this.navigateRoute('profile');
              },
            },
          ];
        }

        envRoles.splice(envRoles.indexOf(constants.AGENCY_ROLE), 1); //deleting
        envRoles.splice(envRoles.indexOf(constants.ARCH_ROLE), 1); //deleting

        let isArchRole = this.authenticationService.isUserInternal();
        if (!isArchRole) {
          adminMenu = [];
          nonAdminMenu = [];
          agencyMenu = [
            {
              text: 'My Profile',
              action: () => {
                this.navigateRoute('profile');
              },
            },
          ];
        }

        return [...agencyMenu, ...adminMenu, ...nonAdminMenu];
      } else {
        return [];
      }
    } else {
      return [];
    }
  }

  navigateToAdmin() {
    window.open(environment.aipAdminRedirectUrl + constants.adminPortalEndpoint, '_self');
  }
  navigateRoute(route: string) {
    let url = environment.appUrl + route;
    window.open(url, '_self');
  }

  getMenu(): Observable<any> {
    return this.apiHttpService
      .post(this.apiEndPoint.createUrl(constants.topmenuEndPoint, true), {
        appName: 'AIGI-BasePortal',
        rolesList: ['Menuset_GlobalLandingPage'],
      })
      .pipe(
        map((result: any) => {
          let dataMenu = result.map((data: any) => ({
            text: data.name,
            url: data.action,
          }));

          return dataMenu;
        })
      );
  }
}
