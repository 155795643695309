import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from '@core';
import { SharedModule } from '@shared';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import {
  AptNavigationModule,
  AptButtonModule,
  AptTemplatesModule,
  AptAuthSyncModule,
} from '@archinsurance-da/arch-portal-tools';
import { FlexLayoutModule } from '@angular/flex-layout';
@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    FontAwesomeModule,
    SharedModule,
    LoginRoutingModule,
    FlexLayoutModule,
    AptButtonModule,
    AptNavigationModule,
    AptTemplatesModule,
    AptAuthSyncModule,
  ],
  declarations: [LoginComponent],
})
export class LoginModule {}
