<!--
<apt-floating-header *ngIf="!accessDenied">
  <div fxLayout="row">
    <div fxFlex="80">
      <apt-h2>AIP Base Landing Page.</apt-h2>
    </div>
  </div>
</apt-floating-header>

<apt-content-box *ngIf="!accessDenied">
  <div fxLayout="row" fxLayoutAlign="space-between stretch" class="content-container">
    <div fxFlex="80" fxFill class="content center" style="justify-content: center">
       <apt-h2>Arch Insurance Portals</apt-h2>
      <p>
        Below is the Arch Insurance product portal catalog. If you require access authorization, please click on any of
        the following products below.
      </p>
      <apt-card-access-grid  [portals]="myPortals" *ngIf="myPortals"></apt-card-access-grid>
      <app-loader [isLoading]="!myPortals" [message]="'LandingComponent.portalLoader' | translate"></app-loader>

    </div>
  </div>
</apt-content-box> -->
<!--<apt-floating-header>
  <div fxLayout="row " style="justify-content: center">
    <div fxFlex="80">
      <apt-h2>Arch Insurance Portals</apt-h2>
      <p>
        Below is the Arch Insurance product portal catalog. If you require access authorization, please click on any of
        the following products below.
      </p>
      <apt-card-access-grid [portals]="myPortals" *ngIf="myPortals"></apt-card-access-grid>
      <app-loader [isLoading]="!myPortals" [message]="'LandingComponent.portalLoader' | translate"></app-loader>
    </div>
  </div>
</apt-floating-header>-->
<div fxLayout="row wrap" fxLayoutAlign="start center" class="boxbase">
  <div class="boxOverlay" fxflex="50%" fxLayoutAlign="start center">
    <div fxFlexOffset="50px" style="width: 588px">
      <p class="boxHeader">Welcome to the Arch Portal!</p>
      <p class="boxText">
        Arch is a global leader in providing specialty insurance, reinsurance and mortgage insurance solutions for
        today's challenges and tomorrow's opportunities.
      </p>
    </div>
  </div>
</div>

<div fxLayout="column wrap" fxLayoutAlign="start stretch" class="portal-landing-container">
  <!-- <div fxFlex *ngIf="myPortals" class="portal-blurb">
    If you require access to any of the Arch portals, please make a selection from the products listed below.
  </div> -->

  <div fxFlex *ngIf="myPortals">
    <apt-card-access-grid
      [columnCount]="4"
      [cardMaxWidth]="20"
      [portals]="myPortals"
      [user]="userDetails"
      [requestAccessPath]="senEmailPath"
      *ngIf="myPortals"
    >
    </apt-card-access-grid>
  </div>

  <div fxFlex *ngIf="!myPortals">
    <div *ngIf="!loadedPortals">Please wait, loading portals...</div>
    <div *ngIf="loadedPortals">
      <apt-h2>There was a problem loading the page.</apt-h2>
      <p style="font-weight: 600">Please log out and login again to resolve the problem.</p>
      <p>
        If you continue to experience issues, please contact the
        <a (click)="requestHelp()">Arch Insurance Portals team</a> for assistance.
      </p>
    </div>
  </div>
</div>
<!-- <apt-auth-sync syncWith="{{ synchWithUWPortal }}"></apt-auth-sync>
<apt-auth-sync syncWith="{{ synchWithPaymentPortal }}"></apt-auth-sync> -->
<!-- <apt-auth-sync syncWith="{{ synchWithPLPortal }}"></apt-auth-sync>
<apt-auth-sync syncWith="{{ synchWithUWPortal }}"></apt-auth-sync>
<apt-auth-sync syncWith="{{ synchWithBasePortal }}"></apt-auth-sync> -->

<!-- <iframe style="display: none" #iframePL (load)="onLoadPL(iframePL)" [src]="oldplportalurl"></iframe>
<iframe style="display: none" #iframeAIP (load)="onLoadAIP(iframeAIP)" [src]="aipRedirectUrl"></iframe> -->
