import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AptButtonModule, AptMaintenanceModule, AptTemplatesModule } from '@archinsurance-da/arch-portal-tools';
import { ContentService } from '@shared/services/contentserver.service';
import { LoaderComponent } from './loader/loader.component';
import { AccessDeniedComponent } from './acccess-denied/access-denied.component';
import { TranslateModule } from '@ngx-translate/core';
import { MaintenanceComponent } from './maintenance/maintenance.component';
@NgModule({
  imports: [CommonModule, AptButtonModule, AptTemplatesModule, TranslateModule, AptMaintenanceModule],
  declarations: [LoaderComponent, AccessDeniedComponent, MaintenanceComponent],
  exports: [LoaderComponent, AccessDeniedComponent, MaintenanceComponent],
  providers: [ContentService],
})
export class SharedModule {}
