import packageInfo from '../../package.json';

export const environment = {
  production: false,
  hmr: false,
  version: packageInfo.version + '-dev',
  envName: 'DEV',
  environmentName: 'DEV',
  origins: ['https://aip-base-dev.apps.aks-nonprod.archinsurance.com'],
  aipHostPort: 'http://aigi-drvjbss001:8080/',
  aipHostPort1: 'https://aip-base-dev.apps.aks-nonprod.archinsurance.com/aip-base-api/aip-base-api',
  aipHostPort2: 'https://aip-authorization-ms-dev.apps.aks-nonprod.archinsurance.com',
  aipRedirectUrl: 'http://aigi-drvjbss001.corp.archcapservices.com:8080/archweb',
  aipAdminRedirectUrl: 'https://aip-admin-dev.apps.aks-nonprod.archinsurance.com',
  aipUserProfileRedirectUrl: 'https://stgmy.archinsurance.com',
  oldplportalurl: 'http://aigi-drvjbss001:8080/daspl4',
  quickClaimsUrl: 'http://aigi-drvjbss001:8080/claimsWeb/#/claimIncident',
  aipHostPortAdmin: 'https://aip-admin-api-dev.apps.aks-nonprod.archinsurance.com',
  appUrl: 'https://aip-base-dev.apps.aks-nonprod.archinsurance.com/',
  AUTH_URL: 'https://archinsurance-dev.auth0.com/oauth/token',

  //ENV specific variables
  internalUserFlag: true,
  externalUserFlag: true,
  showDebug: true,
  SKIP_ENV_ROLE_CHECK: true,
};
