import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ContentService } from '@shared/services/contentserver.service';
import { environment } from '@env/environment';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { faChevronRight, faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loading: boolean = false;
  errorLink: boolean = false;
  labelService: any = null;

  //icons
  faChevronRight = faChevronRight;
  faCheckCircle = faCheckCircle;
  faExclamationTriangle = faExclamationTriangle;
  constructor(
    private _sanitizer: DomSanitizer,
    private contentService: ContentService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  getBackground() {
    var bgi = localStorage.getItem('bgImage');
    return this._sanitizer.bypassSecurityTrustStyle(`url(${bgi})`);
  }

  learnMoreClick() {
    window.open('http://www.archcapgroup.com/Insurance/Regions/United-States', '_blank');
  }

  loginClick() {
    if (this.authenticationService.hasValidAccessToken()) {
      location.href = '/home'; //go to dashboard
    } else {
      this.authenticationService.runInitialLoginSequence();
    }
  }

  reportClaimClick() {
    this.errorLink = false;
    this.loading = true;
    let navUrl = environment.quickClaimsUrl;
    window.location.href = navUrl;
  }

  ngOnInit() {
    this.contentService.getDetailsContentServer('CommonFramework').subscribe(
      (dataContentServer) => {
        if (dataContentServer) {
          dataContentServer.forEach((content: any) => {
            if (content.imgSection == 'Background') {
              localStorage.setItem('bgImage', JSON.stringify(content.imgPath));
            }
            if (content.imgSection == 'Messages') {
              localStorage.setItem('messages', JSON.stringify(content.imgPath));
            }
          });
        }
      },
      (dataerror) => {}
    );
  }
}
