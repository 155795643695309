import packageInfo from '../../package.json';

export const environment = {
  production: true,
  hmr: false,
  version: packageInfo.version + '-int',
  envName: 'int',
  basePath: '/home/',
  environmentName: 'INT',
  origins: ['https://aip-base-int.apps.aks-nonprod.archinsurance.com'],
  aipHostPort: 'http://aigi-irvjbss001:8080/',
  aipHostPort1: 'https://aip-base-int.apps.aks-nonprod.archinsurance.com/aip-base-api/aip-base-api',
  aipHostPort2: 'https://aip-authorization-ms-int.apps.aks-nonprod.archinsurance.com',
  aipRedirectUrl: 'http://aigi-irvjbss001.corp.archcapservices.com:8080/archweb',
  aipAdminRedirectUrl: 'https://aip-admin-int.apps.aks-nonprod.archinsurance.com',
  aipUserProfileRedirectUrl: 'https://stgmy.archinsurance.com',
  oldplportalurl: 'http://aigi-irvjbss001:8080/daspl4',
  quickClaimsUrl: 'http://aigi-irvjbss001:8080/claimsWeb/#/claimIncident',
  aipHostPortAdmin: 'https://aip-admin-api-int.apps.aks-nonprod.archinsurance.com',
  appUrl: 'https://aip-base-int.apps.aks-nonprod.archinsurance.com/',
  AUTH_URL: 'https://archinsurance-dev.auth0.com/oauth/token',
  archConnectHost: '/', //for use with api proxy on local

  //ENV specific variables
  internalUserFlag: true,
  externalUserFlag: true,
  showDebug: true,
  SKIP_ENV_ROLE_CHECK: true,
};
