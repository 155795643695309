<div class="homeScreen">
  <apt-template-page-three-row [decreasePaddingHeight]="true">
    <!--  -->

    <ng-container header>
      <div class="row">
        <div class="col-md-8 col-sm-12 col-xs-12 headerMain headerMainLogo">
          <img class="MainLogoClasses" src="assets/images/arch-insurance-logo-white.svg" height="36" />
        </div>
        <div class="col-md-4 col-sm-12 col-xs-12 headerMain">
          <div id="divClaim" class="headerClaims" (click)="reportClaimClick()">
            Report a Claim/Incident
            <img *ngIf="loading" src="assets/images/spin.gif" />
            <div *ngIf="errorLink" class="divClaimsLinkError">Error reporting Claim</div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container main>
      <div [style.background-image]="getBackground()" class="bgImageLoginCss">
        <div class="row" style="padding-top: 60px">
          <div class="col-lg-1 col-md-1 col-sm-1 hidden-xs"></div>
          <div class="col-sm-11 col-xs-12">
            <div class="row">
              <div class="divContentHeading" [innerHTML]="'loginComponent.contentHeading' | translate"></div>
            </div>
          </div>
        </div>
        <!-- <div class="row" style="height: 15px;"></div> -->
        <div class="row" style="margin-top: 30px">
          <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
          <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
            <div class="row">
              <div class="divContentText" style="min-height: 75px">
                {{ 'loginComponent.contentText' | translate }}
              </div>
            </div>
          </div>
        </div>

        <div class="container contentMiddle">
          <div class="row">
            <div class="col-sm-6 contentMiddleCol">
              <div class="middleTextContent" (click)="reportClaimClick()">
                <fa-icon class="blue-row-icon" [icon]="faExclamationTriangle"></fa-icon>
                <div class="middleText">
                  <h5>Report a Claim</h5>
                  <div class="infoContent">
                    <div>{{ 'loginComponent.contentReport' | translate }}</div>
                    <div>{{ 'loginComponent.contentReportClaim' | translate }}</div>
                  </div>
                </div>
                <fa-icon [icon]="faChevronRight"></fa-icon>
              </div>
            </div>

            <div class="col-sm-6 contentMiddleCol">
              <div class="middleTextContent" (click)="loginClick()">
                <fa-icon class="blue-row-icon" [icon]="faCheckCircle"></fa-icon>
                <div class="middleText">
                  <h5>Login</h5>
                  <div class="infoContent">
                    <div>{{ 'loginComponent.contentLogin' | translate }}</div>
                    <div>{{ 'loginComponent.contentLoginProceed' | translate }}</div>
                  </div>
                </div>
                <fa-icon [icon]="faChevronRight"></fa-icon>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container desc-container">
        <div class="row contentLast">
          <div class="col-xs-12">
            <div class="row orange-left">
              <p [innerHTML]="'loginComponent.contentFirst' | translate"></p>
              <p>{{ 'loginComponent.contentSecond' | translate }}</p>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- </div> -->
    <ng-container footer>
      <apt-footer></apt-footer>
    </ng-container>
  </apt-template-page-three-row>
</div>
