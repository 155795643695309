import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../../app.config';
import { environment } from '@env/environment';
import { tap } from 'rxjs/operators';

import { AuthenticationService } from '@app/authentication/authentication.service';
/**
 * Prefixes all requests not starting with `http[s]` with `environment.serverUrl`.
 */
@Injectable({
  providedIn: 'root',
})
export class ApiPrefixInterceptor implements HttpInterceptor {
  constructor(private appConfig: AppConfig, private authenticationService: AuthenticationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!/^(http|https):/i.test(request.url)) {
      request = request.clone({ url: this.appConfig.serverUrl + request.url });
    }

    if (localStorage.getItem('id_token') !== null && request.url !== environment.AUTH_URL) {
      let idToken = localStorage.getItem('id_token').toString();
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${idToken}`,
          'Content-Type': 'application/json',
        },
      });
    }

    return next.handle(request);
    // return next.handle(request).pipe(
    //   tap(
    //     () => {},
    //     (err: any) => {
    //       if (err instanceof HttpErrorResponse) {
    //         if (err.status !== 401) {
    //           return;
    //         } else {
    //           this.authenticationService.logout();
    //         }
    //       }
    //     }
    //   )
    // );
  }
}
