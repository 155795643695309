import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService, UserProfile } from '@app/authentication/authentication.service';
import { MenuItem, RolesAndPortals } from '@archinsurance-da/arch-portal-tools';
import { HeaderService } from './header.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  menuHidden = true;
  menuItems: MenuItem[];
  homeRoute = 'home';
  //SETTINGS MENU
  settingsItems: any;
  rolesAndPortals: RolesAndPortals = {
    defaultPortal: {
      //our vertical portal does not exist in AIP, so manually adding it
      'Arch Portal': 'http://localhost:3000',
    },
    portals: [],
  };
  envName: string = environment.environmentName;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private headerService: HeaderService
  ) {}

  ngOnInit() {
    this.settingsItems = this.headerService.getSettingMenu();
    this.headerService.getMenu().subscribe(
      (data: any) => {
        data.forEach((menu: any) => {
          if (new RegExp(/https?:\/\//).test(menu.url)) {
            Object.defineProperty(menu, 'action', {
              value: () => this.menuClick(menu.url),
            });
          } else {
            //if not we assume it is a slug that the angular router will handle auto

            Object.defineProperty(menu, 'routerLink', {
              value: menu.url,
            });
          }
        });
        this.menuItems = data;
      },
      (dataError: any) => {
        console.log('warn', dataError);
      }
    );
    // this.headerService.getRolesAndPortals().subscribe(
    //   (data: any) => {
    //     // console.log('data', data);
    //     data.defaultPortal = {
    //       //our vertical portal does not exist in AIP, so manually adding it
    //       'Arch Insurance Portal': 'http://localhost:3000',
    //     };
    //     this.rolesAndPortals = data;
    //   },
    //   (dataError: any) => {
    //     console.log('warn', dataError);
    //   }
    // );
  }

  menuClick(urlSlug: any) {
    window.open(urlSlug, '_blank');
  }

  homeMenuClick() {
    // window.open(environment.aipLandingUrl, "_self");
    this.router.navigate(['/home']);
  }
  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }

  logout() {
    this.authenticationService.logout();
  }
  doLogout() {
    return () => {
      this.authenticationService.logout();
    };
  }

  get userProfile(): UserProfile {
    return this.authenticationService.userProfile;
  }
}
