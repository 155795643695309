import packageInfo from '../../package.json';

export const environment = {
  production: false,
  hmr: false,
  version: packageInfo.version + '-dev',
  envName: 'LOCAL',
  environmentName: 'INT',
  origins: ['http://localhost:4000'],
  appUrl: 'https://aip-base-dev.apps.aks-nonprod.archinsurance.com/',
  aipHostPort: 'http://aigi-irvjbss001:8080/',
  aipHostPort1: 'https://aip-base-int.apps.aks-nonprod.archinsurance.com/aip-base-api/aip-base-api',
  aipHostPort2: 'https://aip-authorization-ms-int.apps.aks-nonprod.archinsurance.com',
  aipRedirectUrl: 'http://localhost:3000',
  aipHostPortAdmin: 'https://aip-admin-api-int.apps.aks-nonprod.archinsurance.com',

  aipAdminRedirectUrl: 'https://aip-admin-int.apps.aks-nonprod.archinsurance.com',
  aipUserProfileRedirectUrl: 'https://stgmy.archinsurance.com',
  oldplportalurl: 'http://localhost:4201',
  quickClaimsUrl: 'http://aigi-drvjbss001:8080/claimsWeb/#/claimIncident',
  AUTH_URL: 'https://archinsurance-dev.auth0.com/oauth/token',
  archConnectHost: '/', //for use with api proxy on local

  //ENV specific variables
  internalUserFlag: true,
  externalUserFlag: true,
  showDebug: true,
  SKIP_ENV_ROLE_CHECK: false,
};
