import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login.component';
import { LoginGuard } from './login.guard';
const routes: Routes = [{ path: 'login', component: LoginComponent, pathMatch: 'full' }]; //, canActivate: [LoginGuard]//going to let people land here regardless of logged in state

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LoginRoutingModule {}
