import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ApiEndpointsService } from '@core/http/api-endpoints.service';
import { ApiHttpService } from '@core/http/api-http.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { constants } from './../@shared/constants';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(
    private apiEndPoint: ApiEndpointsService,
    private http: HttpClient,
    private apiHttpService: ApiHttpService
  ) {}
  private handleError(err: HttpErrorResponse) {
    return Observable.throw(err.error() || 'Server error');
  }

  getUserByEmail(email: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    var idToken = localStorage.getItem('id_token').toString();
    headers = headers.append('Authorization', 'Bearer ' + idToken);
    return this.http
      .post<any>(environment.aipHostPort1 + constants.serviceEndPointUserDetail + email, null, { headers })
      .pipe(
        map((contentServer) => {
          return contentServer;
        }),
        catchError(this.handleError)
      );
  }

  getUserSignature(email: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    var idToken = localStorage.getItem('id_token').toString();
    headers = headers.append('Authorization', 'Bearer ' + idToken);
    return this.http
      .get<any>(environment.aipHostPortAdmin + constants.getSignature + '/' + encodeURIComponent(email), { headers })
      .pipe(
        map((contentServer) => {
          return contentServer;
        }),
        catchError(this.handleError)
      );
  }

  updateSignature(signatureData: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    var idToken = localStorage.getItem('id_token').toString();
    headers = headers.append('Authorization', 'Bearer ' + idToken);
    return this.http
      .post<any>(environment.aipHostPortAdmin + constants.updateSignature, signatureData, { headers })
      .pipe(
        map((contentServer) => {
          return contentServer;
        }),
        catchError(this.handleError)
      );
  }

  /** The function is used to delete existing user
   * @param {string} email - user email
   */
  deleteExistingUser(email: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    var idToken = localStorage.getItem('id_token').toString();
    headers = headers.append('Authorization', 'Bearer ' + idToken);
    // return this.http.post<any>("http://localhost:8007/" + environment.serviceEndPointDeleteUser + "/" + email, "", { headers }).pipe(
    //   map(addResult => {
    //     return (addResult);
    //   }));
    return this.http
      .post<any>(
        environment.aipHostPortAdmin + constants.serviceEndPointDeleteUser + '/' + encodeURIComponent(email),
        '',
        { headers }
      )
      .pipe(
        map((addResult) => {
          return addResult;
        })
      );
  }

  fetchRolesAgenciesAndProducts(selectedUserId: any, loggedInUserId: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    var idToken = localStorage.getItem('id_token').toString();
    headers = headers.append('Authorization', 'Bearer ' + idToken);
    let endpoint = '';

    // get list of users (based on logged in user)
    // endpoint =
    //   environment.serviceEndPointGetRolesAgenciesAndProducts +
    //   '?userId=' +
    //   selectedUserId +
    //   '&loggedInUserId=' +
    //   loggedInUserId;

    endpoint =
      constants.serviceEndPointGetRolesAgenciesAndProducts +
      '?userEmailId=' +
      encodeURIComponent(selectedUserId) +
      '&loggedInEmailId=' +
      encodeURIComponent(loggedInUserId);

    // return this.http.get<any>("http://localhost:8007/" + endpoint, { headers }).pipe(
    //   map(agencyResult => {
    //     return (agencyResult);
    //   }));
    return this.http.get<any>(environment.aipHostPortAdmin + endpoint, { headers }).pipe(
      map((agencyResult) => {
        return agencyResult;
      })
    );
  }

  /** The function is used to get Portals Branch by Email
   * @param {string} selectedEmail - selected Email
   */
  fetchPortals(selectedEmail: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    var idToken = localStorage.getItem('id_token').toString();
    headers = headers.append('Authorization', 'Bearer ' + idToken);
    let endpoint = '';

    endpoint = constants.serviceEndPortals + '?emailId=' + encodeURIComponent(selectedEmail);

    // return this.http.get<any>("http://localhost:8007/" + endpoint, { headers }).pipe(
    //   map(agencyResult => {
    //     return (agencyResult);
    //   }));
    return this.http.get<any>(environment.aipHostPortAdmin + endpoint, { headers }).pipe(
      map((agencyResult) => {
        return agencyResult;
      })
    );
  }

  /** The function is used to add new user
   * @param {string} userData - user data object
   */
  addNewUser(userData: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    var idToken = localStorage.getItem('id_token').toString();
    headers = headers.append('Authorization', 'Bearer ' + idToken);
    // return this.http.post<any>("http://localhost:8007/" + environment.serviceEndPointaddUser, [userData], { headers }).pipe(
    //   map(addResult => {
    //     return (addResult);
    //   }));
    return this.http
      .post<any>(environment.aipHostPortAdmin + constants.serviceEndPointaddUser, [userData], { headers })
      .pipe(
        map((addResult) => {
          return addResult;
        })
      );
  }
}
