import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { constants } from './../../@shared/constants';

@Injectable()
export class ContentService {
  constructor(private http: HttpClient) {}

  private handleError(err: HttpErrorResponse) {
    return Observable.throw(err.error() || 'Server error');
  }
  getDetailsContentServer(appName: string): Observable<any> {
    return this.http.get<any>(environment.aipHostPort + constants.serviceEndPointsContentServer + appName).pipe(
      map((contentServer) => {
        return contentServer;
      }),
      catchError(this.handleError)
    );
  }
  claimsUserService(): Observable<any> {
    return this.http.post<any>(environment.aipHostPort + constants.userClaims, {}).pipe(
      map((result) => {
        return result;
      })
    );
  }
  getConstants() {
    return this.http.get<any>('assets/jsons/messages.json').pipe(
      map((returnResult) => {
        return returnResult;
      })
    );
  }
}
