import { Component, OnInit } from '@angular/core';
import { HomeService } from './home.service';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthenticationService, UserProfile } from '@app/authentication/authentication.service';

import { constants } from '@app/@shared/constants';
import { MaintenanceSchedule, MaintenanceService, PortalRolesService } from '@archinsurance-da/arch-portal-tools';
import { forkJoin } from 'rxjs';
import { ProfileService } from '@app/profile/profile.service';
import { HeaderService } from '@app/shell/header/header.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  myPortals: any;
  loadedPortals: boolean = false;
  userDetails: any = {};
  oldplportalurl: SafeResourceUrl;
  aipRedirectUrl: SafeResourceUrl;
  userId: any = '';
  senEmailPath: string = '';
  constructor(
    private homeService: HomeService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private authenticationService: AuthenticationService,
    private maintenanceService: MaintenanceService,
    private profileService: ProfileService,
    private headerService: HeaderService,
    private portalRolesService: PortalRolesService
  ) {
    this.oldplportalurl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.oldplportalurl + '/auth-sync.html');
    this.aipRedirectUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.aipRedirectUrl + '/auth-sync.html');
  }

  ngOnInit() {
    //call selfheal service on start
    //this.homeService.selfHealUserRoles().subscribe((data: any) => {});

    const startMDate = new Date(constants.maintenanceStartDate);
    const endMDate = new Date(constants.maintenanceEndDate);

    startMDate.toDateString();

    endMDate.toDateString();

    let shedules: MaintenanceSchedule[] = [
      {
        // title: 'This site is temporaily unavailable for maintenance from',
        startDTS: startMDate,
        endDTS: endMDate,
        // description: 'We apologize for the inconvenience. Thank you.',
        warnMinutesBefore: constants.warnMinutesBefore,
      },
    ];

    //Change startDTS and endDTS to show currentMain
    const currentMaintenances = this.maintenanceService.isScheduledMaintenance(shedules, true); //IF second param is false means do not show upcoming
    const currentMaint = currentMaintenances[0];
    if (currentMaint) {
      this.router.navigate(['/maintenance'], { skipLocationChange: true });
    }

    let token = localStorage.getItem('access_token');
    this.senEmailPath = environment.aipHostPort1 + constants.sendEmailPath;

    this.userDetails = {};
    let userEmail = this.headerService.getUserEmail();
    let fullName = this.headerService.getUserfullName();

    // console.log(fullName, userEmail);
    // console.log(environment.environmentName);
    if (!userEmail) {
      this.loadedPortals = true;
      console.log('warn', 'Missing Email Id');
    }

    this.userId = userEmail;
    this.loadedPortals = true;

    let dashboradPortal = this.portalRolesService.getDashboardPortals(environment.environmentName.toLowerCase());
    let portalRoleData = this.portalRolesService.getPortalRolesData();
    this.myPortals = this.homeService.roleCheck(dashboradPortal, portalRoleData);
    Object.assign(this.userDetails, {
      name: fullName,
      email: userEmail,
      token: token, //access_token
      userId: this.userId,
    });

    this.myPortals.map((item: any, i: number) => {
      let encodedKey = btoa(item.title + '-' + this.userId);
      let key = JSON.parse(localStorage.getItem(`access-requested-${encodedKey}`));
      if (key && key.value) {
        if (!item.enabled) this.myPortals[i]['pending'] = true;
        else localStorage.removeItem(key);
      }
    });
    //}
    //   },
    //   (dataError: any) => {
    //     this.loadedPortals = true;
    //     console.log('warn', dataError);
    //   }
    // );
  }
  get userProfile(): UserProfile {
    return this.authenticationService.userProfile;
  }
  requestHelp() {
    const nl = '%0D%0A';
    const mailurl = `mailto:ProductTeamAIP@archcapservices.com?subject=Arch Insurance Portal Access Page Issue&body=Greetings,${nl}${nl}I am having troubles accessing the AIP base portal landing page.${nl}${nl}Name:${nl}Email:${nl}${nl}Thank you.`;
    window.open(mailurl, '_blank').focus();
  }
}
